.nav_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav__links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-links {
  text-decoration: none;
  color: white;
  border-bottom: 2px solid transparent;
  transition: 0.5s ease all;
}
.nav-links:hover {
  color: white;
  border-bottom: 2px solid  #ec2027;
}
.nav-links.active {
  border-bottom: 2px solid #ec2027;
  color:white;
}
.nav--links {
  text-decoration: none;
  color: #000000;
  border-bottom: 2px solid transparent;
  transition: 0.5s ease all;
}
.nav--links:hover {
  color: #000000;
  border-bottom: 2px solid  #ec2027;
}
.nav--links.active {
  border-bottom: 2px solid #ec2027;
  color: #000000;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
}
.topbarconfluid{
  background-color:  #000000;
}

.social_icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.contact__topbar {
  display: flex;
  align-items: center;
}
.mobile_links {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #222;
  z-index: 999;
  height: 100%;
  padding: 10px;
  color: #ffffff;
}

.harr{
  cursor: pointer;
}

.mobile__links {
  text-decoration: none;
  color: #ffffff;
}
.mobile_links :hover{
  color: #f9a634;
}
.menu {
  display: none;
}
.services {
  position: absolute;
  top: 85px;
  background: #ffffff;
  padding: 10px;
  right: 40px;
  width: 200px;
  text-align: center;
  box-shadow: 0px 2px 0px 1px;
  border-radius: 10px;
}
.shiplogo{
  height: 6vh;
} 
.logo{
  height: 6vh;
  margin-top: 2px;
}
.navbarfix{
  position: absolute;
    width: 100%;
    z-index: 1000;
}
@media screen and (max-width: 768px) {
  .social_icons {
    display: none !important;
  }
  .contact__topbar {
    justify-content: space-between;
    margin-left: 12px;
  }
  .nav__links {
    display: none;
  }
  .menu {
    display: block;
  }
  .logo {
    width: 150px;
    height: 40px;
    /* margin-left: -13px !important; */
  }
}

@media screen and (min-width: 991px) and (max-width: 1030px) {
  .nav-links{margin-left: -12px;}
}