.subscribe_bg {
  background-image: url("../../assests/images/banner/subscribe-bg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 48vh !important;
}
.subscribe_bg_overlay {
  background-color: #21212194;
  /* opacity: 0.7; */
  display: grid;
  place-items: center;
  align-items: center;
  height: 100%;
}
.subscribe_input {
  background: #ffffff;
  border: none;
  padding: 12px;
  cursor: pointer;
  width: 100%;
}
.subscribe_input:focus {
  outline: none;
}
.up{
  margin-top: -2px;
}


@media screen and (min-width: 991px) and (max-width: 1030px) {
.subscribe_bg{
height: 28vh !important;
}
.sign
{
  height: 48px;
}
.up{
  font-size: 1px;
}
}
@media screen and (max-width: 768px) {
  .subscribe_bg{
    height: 50vh !important;
    }
    .sii{
      margin-top: 12px !important;
    }
    .subscribeheading{
      text-align: left;
      font-size: 28px !important;
    }
    .subscribepara{
      text-align: left;
    }
}

@media screen and (min-width: 1600px) and (max-width: 2000px) {
  .subscribe_bg{height: 40vh !important;}
}


@media screen and (min-width: 1031px) and (max-width: 1366px) {
  .subscribe_bg{height: 35vh;}
}

@media screen and (min-width: 820px) and (min-height:1180px) {
  .subscribe_bg{height: 35vh !important;}
  .sign{height: 48px !important;}
  .sii{font-size: 12px ;}
 
}
@media screen and (min-width:1024px)and (min-height: 1366px){ 
.subscribe_bg{height: 25vh !important;}  
}
