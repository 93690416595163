::-webkit-scrollbar {
  display: none;
}

.footertext {
  text-align: left;
  display: inline;
  position: relative;
}
.footertext::after {
  content: "";
  width: 0.1px;
  display: block;
  background-color: black;
  position: absolute;
  right: 0;
  top: 52%;
  height: 50%;
  transform: translateY(-50%);
}

.link {
  color: #000;
  font-size: 14px;
}
.link:hover {
  color: #ec2723;
}

.filinkk {
  color: #000;
}

.nav-linkss {
  display: grid;
}

.imp {
  color: #ec2723;
  display: grid !important;
  place-items: center;
  position: relative;
}
.imp::after {
  content: "";
  width: 0.1px;
  display: block;
  background-color: black;
  position: absolute;
  right: 0;
  top: 52%;
  height: 50%;
  transform: translateY(-50%);
}
.parte {
  font-size: 13px;
  text-align: left;
}

.lettr {
  font-size: 20px;
  color: #ec2723;
}
.addd {
  font-size: 13px;
  color: #000;
}

.bordered {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputt {
  cursor: pointer;
  border: 1px solid #000;
  border-right: none;
}
.shiplogo {
  margin-top: 70px;
}

.iconss {
  border: 1px solid #000000;
  color: #000000;
  width: 40px;
  height: 40px;
  margin-top: 15px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.iconss:hover {
  animation-name: scale;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  cursor: pointer;
  color: #ffa500;
  border: 1px solid #ffa500;
}

@media screen and (max-width: 768px) {
  .parte {
    margin-left: 5px;
  }
  .footertext {
    border-bottom: 1px solid black !important;
    text-align: left;
    margin-left: -8px;
    /* padding-right: 18px !important; */
  }
  .footertext::after {
    display: none;
  }
  .iconss {
    margin-bottom: 15px;
  }
  .imp {
    margin-top: 20px !important;
    margin-left: -3px;
    border-bottom: 1px solid black !important;
  }
  .imp::after {
    display: none;
  }
  .lettr {
    margin-top: -52px;
    margin-left: -40px;
  }
  .addd {
    margin-left: -42px;
  }
  .inputt {
    margin-left: -42px;
  }
}
@media screen and (min-width: 991px) and (max-width: 1030px) {
  .shiplogo {
    width: 100%;
  }
}
@media screen and (min-width: 1280px) and (min-height: 800px) {
  .imp::after {
    content: "";
    width: 0.1px;
    display: block;
    background-color: black;
    position: absolute;
    right: 0;
    top: 52%;
    height: 50%;
    transform: translateY(-50%);
  }
  .footertext::after {
    content: "";
    width: 0.1px;
    display: block;
    background-color: black;
    position: absolute;
    right: 0;
    top: 52%;
    height: 50%;
    transform: translateY(-50%);
  }
}
